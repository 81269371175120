<template>
  <div class="h-screen flex w-full bg-img">
    <div class="py-10 px-5 w-full lg:w-3/4 xl:w-2/3 mx-auto">
      <vx-card class="h-full">
        <div class="flex border-line pb-3 items-center text-primary">
          <feather-icon svgClasses="h-12 w-12" icon="ShieldIcon"/>
          <p class="font-semibold text-xl">{{$t('Terms of Service')}}</p>
        </div>
        <iframe id="terms-iframe" src="https://shipblu.com/ar/terms-and-conditions-iframe/" width="100%" />
        <div class="md:flex mt-5 items-center justify-between">
          <div class="flex">
            <input v-model="termsCheck" class="w-5 h-5" :class="errors.first('terms check') ? 'require-checked' : ''" type="checkbox" name="terms check" v-validate="'required'"/>
            <p @click="termsCheck = !termsCheck" class="font-semibold ml-2 cursor-pointer text-sm">{{$t('I have read and agree to the Terms and Conditions.')}}</p>
          </div>
          <div class="md:mt-0 mt-3 text-right">
            <vs-button type="border" class="mr-3" @click="cancelTerms">{{$t('Decline')}}</vs-button>
            <vs-button id="accept-btn-loading" :disabled="!termsCheck" @click="acceptTerms">{{$t('Accept')}}</vs-button>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { sendRequest } from '../../http/axios/requestHelper'
export default {
  data () {
    return {
      termsCheck: false
    }
  },
  methods: {
    cancelTerms () {
      window.open('https://shipblu.com')
    },
    acceptTerms () {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#accept-btn-loading',
        scale: 0.45
      })
      sendRequest(true, false, this, `api/v1/users/merchants/${this.$store.state.AppActiveUser.uid}/`, 'patch', {agree_terms_and_conditions: true}, true,
        () => {
          this.$vs.loading.close('#accept-btn-loading > .con-vs-loading')
          this.$auth.login({ target: this.$route.query.to })
        }
      ) 
    }
  }
}
</script>

<style>
.border-line {
  border-bottom: solid 2px #E4E4E4;
}
#terms-iframe {
  border: unset !important;
  height: calc(100vh - 229px);
}
@media (max-width: 767px) {
  #terms-iframe {
    height: calc(100vh - 258px);
  }
}
@media (max-width: 430px) {
  #terms-iframe {
    height: calc(100vh - 276px);
  }
}
</style>